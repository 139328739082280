import React, { useEffect, useState } from "react";
import "./connectbank.scss";

const PlaidConnection = (props) => {
  const [clientid, setClientid] = useState(props.clientCode);
  const [linkToken, setLinkToken] = useState(null);
  const [isPlaidLoaded, setIsPlaidLoaded] = useState(false);
  const [accounts, setAccounts] = useState([]);
  console.log("clientid", clientid);

  useEffect(() => {
    // Load Plaid Link SDK dynamically
    const script = document.createElement("script");
    script.src = "https://cdn.plaid.com/link/v2/stable/link-initialize.js";
    script.async = true;
    script.onload = () => {
      console.log("✅ Plaid script loaded successfully");
      setIsPlaidLoaded(true); // Set flag when loaded
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    if (!isPlaidLoaded) return; // ✅ Ensure Plaid is loaded

    const fetchExistingAccounts = async () => {
      try {
        console.log("🔎 Checking if access token exists for client:", clientid);

        const response = await fetch(
          `http://localhost:8000/api/accounts/${clientid}`,
          {
            method: "GET",
            headers: { "Content-Type": "application/json" },
            credentials: "include",
          }
        );

        if (response.status === 401) {
          console.warn("⚠️ No stored access token. Fetching link token...");

          // 🔥 Fetch Link Token before launching Plaid
          const linkTokenResponse = await fetch(
            `http://localhost:8000/api/create_link_token/${clientid}`,
            {
              method: "GET",
              headers: { "Content-Type": "application/json" },
              credentials: "include",
            }
          );

          const linkTokenData = await linkTokenResponse.json();
          if (linkTokenData.link_token) {
            console.log("✅ Link token received:", linkTokenData.link_token);

            // 🛑 WAIT before launching Plaid
            setLinkToken(linkTokenData.link_token);

            setTimeout(() => {
              console.log("🚀 Launching Plaid now...");
              launchPlaid(linkTokenData.link_token); // ✅ Pass link token explicitly
            }, 500);
          } else {
            console.error("❌ Failed to fetch link token:", linkTokenData);
          }
          return;
        }

        const data = await response.json();
        if (data.accounts && data.accounts.length > 0) {
          setAccounts(data.accounts);
          console.log("✅ Fetched existing bank accounts:", data.accounts);
        } else {
          console.log("ℹ️ No accounts found. User may need to connect.");
          launchPlaid(linkToken); // ✅ Ensure linkToken is passed correctly
        }
      } catch (error) {
        console.error("❌ Error checking existing accounts:", error);
      }
    };

    fetchExistingAccounts();
  }, [isPlaidLoaded]); // ✅ Trigger only after Plaid SDK loads

  const fetchBankAccounts = async () => {
    try {
      console.log("🔎 Fetching bank accounts for client:", clientid);

      const response = await fetch(
        `http://localhost:8000/api/accounts/${clientid}`,
        {
          method: "GET",
          headers: { "Content-Type": "application/json" },
          credentials: "include",
        }
      );

      const data = await response.json();

      if (response.status === 401) {
        console.warn(
          "⚠️ No stored access token, starting Plaid authorization..."
        );
        launchPlaid(); // 🔥 Start Plaid authorization process
        return;
      }

      setAccounts(data.accounts || []);
      console.log("✅ Bank Accounts:", data.accounts);
    } catch (error) {
      console.error("❌ Error fetching bank accounts:", error);
    }
  };

  const launchPlaid = (token) => {
    if (!isPlaidLoaded || !window.Plaid) {
      console.error("❌ Plaid SDK is not loaded yet");
      return;
    }

    if (!token) {
      console.error("❌ Link token is missing, cannot launch Plaid.");
      return;
    }

    const handler = window.Plaid.create({
      token, // ✅ Use the token from the parameter
      onSuccess: async (public_token, metadata) => {
        console.log("🎉 Plaid Success! Public Token:", public_token, metadata);
        console.log("🔄 Sending public token to backend...");

        try {
          const response = await fetch(
            "http://localhost:8000/api/exchange_public_token",
            {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              credentials: "include",
              body: JSON.stringify({ public_token, clientid }), // ✅ Include clientid
            }
          );

          const data = await response.json();
          console.log("✅ Frontend received token exchange response:", data);

          if (data.success) {
            console.log("🔎 Fetching bank accounts now...");
            fetchBankAccounts(); // ✅ Auto-fetch accounts after a successful token exchange
          } else {
            console.error("❌ Failed to exchange public token.");
          }
        } catch (error) {
          console.error("❌ Error exchanging public token:", error);
        }
      },
      onExit: (err, metadata) => {
        if (err) {
          console.error("❌ Plaid Error:", err);
        } else {
          console.log("✅ Plaid closed successfully", metadata);
        }
      },
      onEvent: (eventName, metadata) => {
        console.log(`📢 Plaid Event: ${eventName}`, metadata);
      },
    });

    handler.open();
  };

  return (
    <div className="plaid-button-container">
      <button
        className="plaid-button"
        onClick={launchPlaid}
        disabled={!linkToken}
      >
        {linkToken ? "🔗 Connect To My Bank" : "⏳ Loading..."}
      </button>

      {accounts.length > 0 && (
        <div className="accounts-list">
          <h3>🏦 Connected Accounts:</h3>
          <ul>
            {accounts.map((account, index) => (
              <li key={index}>
                <strong>{account.name}</strong> - $
                {account.balances.current.toFixed(2)}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default PlaidConnection;
